import {Button, Divider, IconButton, Stack, TextField, Typography} from '@mui/material';
import {Fragment, useState} from 'react';
import DiscountPicker from 'src/pages/products/common/DiscountPicker';
import {fixPositiveNumbers, updateArray} from 'src/utils/helperMethods';
import ProductPurchaseItemRecord from '../common/ProductPurchaseItemRecord';
import {printProductPrice} from 'src/features/printer';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import {canAccess} from 'src/utils/permissions';
import {showUpdateProductQtyModal} from '../common/UpdateProductQtyModal';

export default function VariationsForm({
	showSort = false,
	productInventoryChanges = [],
	variations,
	setVariations = [],
	values,
	refetch,
	productId,
}) {
	const [state, setState] = useState(0);
	const properties = variations?.[0]?.values?.flatMap((v) => v?.property?.name) ?? [];
	return (
		<Stack spacing={2}>
			{/* we don't want to show sort in add product page because variatons doesn't contain required info */}
			{showSort && (
				<Stack alignItems="center" spacing={0} direction="row">
					<Typography fontWeight={'500'}>ترتيب حسب: </Typography>
					{properties.map((p, index) => {
						return (
							<Fragment key={index}>
								<Button sx={{color: state !== index && 'gray'}} onClick={(e) => setState(index)}>
									{p}
								</Button>
								{index < properties.length - 1 && '-'}
							</Fragment>
						);
					})}
				</Stack>
			)}

			{variations
				.sort((a, b) => {
					const propsA = a.name.split('-')[state];
					const propsB = b.name.split('-')[state];

					return propsA.localeCompare(propsB);
				})
				.map((vars) => {
					return (
						<Stack key={vars.name} spacing={2}>
							<Stack spacing={1} direction="row" justifyContent="space-between">
								<Typography fontWeight={'bold'} sx={{opacity: vars.deleted && 0.4}} variant="body2">
									{vars.name}
								</Typography>
								<Button
									key={vars.name}
									onClick={() =>
										setVariations(updateArray(variations, {...vars, deleted: !vars.deleted}, 'name'))
									}
								>
									{vars.deleted ? 'استرجاع' : 'حذف'}
								</Button>
							</Stack>

							<Stack direction="row" spacing={2} alignItems={'center'}>
								<TextField
									disabled={vars.deleted}
									InputLabelProps={{shrink: true}}
									sx={{opacity: vars.deleted && 0.4, flex: '1 1 auto'}}
									key={`${vars.name}-price`}
									onWheel={(e) => e.target.blur()}
									onKeyDown={(e) => {
										if (/^[0-9]*$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') {
											return;
										}
										e.preventDefault();
									}}
									onChange={(e) => {
										setVariations(
											updateArray(variations, {...vars, price: fixPositiveNumbers(e.target.value)}, 'name'),
										);
									}}
									value={vars.price ?? values.price}
									type="text"
									inputMode="numeric"
									pattern="\d*"
									label="السعر"
								/>

								<DiscountPicker
									sx={{maxWidth: 80, opacity: vars.deleted && 0.4, flex: '1 1 auto'}}
									label="تخفيض"
									key={vars.name}
									name="discount"
									value={vars.discount ?? 0}
									onChange={(discountValue) => {
										setVariations(updateArray(variations, {...vars, discount: discountValue}, 'name'));
									}}
								/>

								<IconButton
									size="small"
									onClick={() =>
										printProductPrice({
											price: vars.price ?? values.price,
											discount: vars.discount,
										})
									}
								>
									<PrintRoundedIcon fontSize="small" />
								</IconButton>
							</Stack>
							{/* quantity */}
							{/* if it's new variation we will show text fields only, else we will show button to add quantity */}
							{!values.infinite &&
								(vars.id == null ? (
									<Stack direction={{md: 'row'}} spacing={{xs: 1, md: 2}}>
										<TextField
											id="addedQuantity"
											name="addedQuantity"
											label="الكمية"
											autoComplete="off"
											sx={{flex: '1 1 auto'}}
											type="number"
											value={vars.addedQuantity}
											onChange={(e) =>
												setVariations(
													updateArray(
														variations,
														{...vars, addedQuantity: fixPositiveNumbers(e.target.value)},
														'name',
													),
												)
											}
										/>
										<TextField
											name="cost_per_unit"
											label="تكلفة القطعة"
											autoComplete="off"
											sx={{flex: '1 1 auto'}}
											type="number"
											value={vars.cost_per_unit}
											onWheel={(e) => e.target.blur()}
											onChange={(e) =>
												setVariations(
													updateArray(
														variations,
														{...vars, cost_per_unit: fixPositiveNumbers(e.target.value)},
														'name',
													),
												)
											}
											// {...getFieldProps('costPerUnit')}
											// {...getHelperProps('costPerUnit')}
										/>
									</Stack>
								) : (
									<Stack spacing={2}>
										<Stack
											px={1}
											py={1}
											border={'1px solid #dbdee0'}
											borderRadius={1}
											spacing={2}
											flex={'1 1 auto'}
										>
											<Stack
												direction={'row'}
												spacing={1}
												justifyContent="space-between"
												alignItems={'center'}
											>
												<Stack spacing={1} direction="row">
													<Typography flexGrow={1}>الكمية</Typography>
													<Typography variant={'subtitle1'}>{vars?.quantity}</Typography>
												</Stack>

												<Stack spacing={1} direction="row" justifyContent="space-between">
													{canAccess() && (
														<Button
															onClick={(e) =>
																showUpdateProductQtyModal({
																	variationId: vars.id,
																	productId: productId,
																	onSuccess: () => {
																		refetch();
																	},
																})
															}
															sx={{color: 'gray'}}
														>
															تعديل
														</Button>
													)}
													<Button
														disabled={vars.deleted}
														size={'small'}
														variant="outlined"
														color={vars.quantityChangerOpend ? 'error' : 'primary'}
														// to make button as small as possible
														sx={{minWidth: '1px'}}
														onClick={() =>
															setVariations(
																updateArray(
																	variations,
																	{
																		...vars,
																		addedQuantity: undefined,
																		cost_per_unit: undefined,
																		quantityChangerOpend: !vars.quantityChangerOpend,
																	},
																	'name',
																),
															)
														}
													>
														{vars.quantityChangerOpend ? 'إلغاء' : 'إضافة'}
													</Button>
												</Stack>
											</Stack>
											<Stack display={!vars.quantityChangerOpend && 'none'} spacing={1}>
												<Typography variant="body2">ستضاف الكمية المطلوبة الى المشتريات.</Typography>
												<Stack direction={{md: 'row'}} spacing={{xs: 1, md: 2}}>
													<TextField
														id="addedQuantity"
														name="addedQuantity"
														label="الكمية المطلوبة"
														autoComplete="off"
														sx={{flex: '1 1 auto'}}
														type="number"
														onWheel={(e) => e.target.blur()}
														value={vars.addedQuantity ?? ''}
														onChange={(e) =>
															setVariations(
																updateArray(
																	variations,
																	{...vars, addedQuantity: fixPositiveNumbers(e.target.value)},
																	'name',
																),
															)
														}
													/>
													<TextField
														name="cost_per_unit"
														label="تكلفة القطعة"
														autoComplete="off"
														sx={{flex: '1 1 auto'}}
														type="number"
														value={vars.cost_per_unit ?? ''}
														onWheel={(e) => e.target.blur()}
														onChange={(e) =>
															setVariations(
																updateArray(
																	variations,
																	{...vars, cost_per_unit: fixPositiveNumbers(e.target.value)},
																	'name',
																),
															)
														}
														// {...getFieldProps('costPerUnit')}
														// {...getHelperProps('costPerUnit')}
													/>
												</Stack>
											</Stack>
										</Stack>
									</Stack>
								))}

							<Divider />

							{vars.id && (
								<ProductPurchaseItemRecord
									quantity={vars.quantity}
									refetch={refetch}
									variationId={vars.id}
									items={productInventoryChanges}
								/>
							)}
						</Stack>
					);
				})}
		</Stack>
	);
}
