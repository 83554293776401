import {getUtcDateTimeFromISO} from 'src/utils/date';
import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getNumber} from 'src/utils/numberUtils';
import {getAddress} from 'src/utils/orderUtils';
import {getSite, getSiteCurrency} from 'src/utils/selectors';
import {accountIs} from 'src/utils/siteUtils';
import {getFormattedPhoneNumber} from 'src/utils/stringUtils';

const printJS = require('print-js');
const {printReceipt} = require('src/utils/AdminApi');

function base64ArrayBuffer(arrayBuffer) {
	var base64 = '';
	var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

	var bytes = new Uint8Array(arrayBuffer);
	var byteLength = bytes.byteLength;
	var byteRemainder = byteLength % 3;
	var mainLength = byteLength - byteRemainder;

	var a, b, c, d;
	var chunk;

	// Main loop deals with bytes in chunks of 3
	for (var i = 0; i < mainLength; i = i + 3) {
		// Combine the three bytes into a single integer
		chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

		// Use bitmasks to extract 6-bit segments from the triplet
		a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
		b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
		c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
		d = chunk & 63; // 63       = 2^6 - 1

		// Convert the raw binary segments to the appropriate ASCII encoding
		base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
	}

	// Deal with the remaining bytes and padding
	if (byteRemainder == 1) {
		chunk = bytes[mainLength];

		a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

		// Set the 4 least significant bits to zero
		b = (chunk & 3) << 4; // 3   = 2^2 - 1

		base64 += encodings[a] + encodings[b] + '==';
	} else if (byteRemainder == 2) {
		chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

		a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
		b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

		// Set the 2 least significant bits to zero
		c = (chunk & 15) << 2; // 15    = 2^4 - 1

		base64 += encodings[a] + encodings[b] + encodings[c] + '=';
	}

	return base64;
}
/**
@Deprecated
*/
export const printOrderReceipt = async (orderId, orderUID) => {
	const pdfFile = await printReceipt(orderId, orderUID);
	printJS({printable: base64ArrayBuffer(pdfFile), type: 'pdf', base64: true});
};

/**
 *
 * @param {import('vetrina-types').ContentTypeAttributes<'orders'>} order
 */
export const printJsonReceipt = (order) => {
	const {site_name, logo, ...site} = getSite();

	const cur = getSiteCurrency();
	const logoSize = 80;
	const order_settings = site.config.order_settings ?? {};
	const logoImgTag =
		order_settings.receipt_show_logo && logo?.url
			? `<img width='${logoSize}' height='${logoSize}' src='${logo.url}'/>`
			: '';
	const siteNameTag =
		order_settings.receipt_show_sitename ?? true
			? `<h1 style="text-align:center;" >${site_name}</h1>`
			: '';
	const ordersItems = order.items
		.filter((item) => !item.isDeleted)
		.map((item) => ({
			name: `${item.product.name} ${item.variation?.name ?? ''}`,
			price: calcPriceAfterDiscount(item.price, item.discount),
			total: calcPriceAfterDiscount(item.price, item.discount) * item.quantity,
			quantity: item.quantity,
			sku: item.product?.sku,
		}));

	const getRow = (title, body) => {
		if (isNullOrEmpty(body)) return '';
		return `<p><strong>${title}</strong>: ${body}</p>`;
	};

	const orderItemsTable = `
		<table>
			<tr>
				<th>المنتج</th>
				<th>السعر</th>
				<th>الكمية</th>
				<th>المجموع</th>
			</tr>
			${ordersItems.map(
				(item) => `
				<tr>
					<td>
					<span>${item.name}</span>
					<br/>
					<span>${!isNullOrEmpty(order.sku) ? order.sku : ''}</span>
					</td>
					<td>${item.price}</td>
					<td>${item.quantity}</td>
					<td>${item.total}</td>
				</tr>
				`,
			)}
		</table>
	`;
	const noteTag = !isNullOrEmpty(order.note)
		? `<pre><strong>ملاحظـة</strong>: ${order.note}</pre>`
		: '';

	const phoneString = !isNullOrEmpty(order.customer?.phone)
		? getRow('رقم الهاتف', getFormattedPhoneNumber(order.customer?.phone))
		: '';
	const emailString = getRow('البريد الالكتروني', order.customer?.email);

	const phone2String = !isNullOrEmpty(order.custom_fields?.phone2)
		? getRow('رقم الهاتف الاحتياطي', getFormattedPhoneNumber(order.custom_fields?.phone2))
		: '';
	const selectedDateString = !isNullOrEmpty(order.selected_date)
		? getRow('تاريخ التسليم', getUtcDateTimeFromISO(order.selected_date))
		: '';
	const creatorString =
		accountIs('alassalsweets', true) && !isNullOrEmpty(order.creator)
			? getRow('بواسطة', order.creator.name)
			: '';
	const addressString = order.full_address; //getAddress(order, true);
	const cusomterNameString = !isNullOrEmpty(order.customer?.name)
		? getRow('اسم الزبون', order.customer?.name)
		: '';
	// show subtotal alone only when there is delivery fees
	const includeDeliveryFees = getNumber(order.delivery_price) > 0;
	const subTotalString = includeDeliveryFees ? getRow('مجموع المشتريات', order.subTotal + cur) : '';
	const discountString = order.discount > 0 ? getRow('التخفيض', order.discount + cur) : '';
	const deliveryPriceString = includeDeliveryFees
		? getRow('سعر التوصيل', order.delivery_price + cur)
		: '';

	const shipping_tracking_id = getRow('رقم تتبع الشحنة', order.delivery_info?.shippingId);

	const down_payment_text =
		order.down_payment > 0
			? `					<p><strong>العربون:<strong>${order.down_payment}</p>
		<p ><strong>المتبقي</strong>: ${order.total - order.down_payment}</p>`
			: '';
	const payment_received_text = order.payment_received
		? `<p  style="font-size:22px"> <strong>تم الدفع</strong></p>`
		: '';

	const html = `
		<div style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
		${logoImgTag}
		${siteNameTag}
		</div>
		${getRow('رقم الطلب: ', order.tracking_number)}
		${cusomterNameString}
		${emailString}
		${getRow('تاريخ انشاء الطلب', getUtcDateTimeFromISO(order.created_at))}
 	  	${addressString}
 	  	${shipping_tracking_id}
		${phoneString}
		${phone2String}
 		${orderItemsTable}
 		${subTotalString}
 		${deliveryPriceString}
 		${discountString}
		<p><strong>المجموع الكلي</strong>: ${order.total}${cur}</p>
		${down_payment_text}
		${payment_received_text}

		${selectedDateString}
		${noteTag}
		${creatorString}
		<hr/>
		${order_settings.receipt_footnote ?? ''}
		<div style="margin-bottom:18px;"></div>
 	`;
	printJS({
		printable: html,
		type: 'raw-html',
		header: 'header',
		style: ` table{
			margin-bottom:18px;
			width:100%;
			border-collapse: collapse;} th, td {
			text-align:right;
			padding:8px;
			border:0.5px dashed black;
		  } html{font-size:14px;direction:rtl;font-family:default} .custom-h3 { color: red; }`,
	});

	// JSON table ( there's no way to add footer for the total -_-)
	/* 	printJS({
		printable: [{name: 'hate', price: '100', quantity: '2', total: 2 * 100}],
		type: 'json',
		properties: [
			{displayName: 'المنتج', field: 'name'},
			{displayName: 'السعر', field: 'price'},
			{displayName: 'الكمية', field: 'quantity'},
			{displayName: 'المجموع', field: 'total'},
		],
		header: header,
		style: 'html{direction:rtl;font-family:Tajawal} .custom-h3 { color: red; }',
	}); */
};

export const printProductPrice = (product) => {
	const {site_name, logo, ...site} = getSite();
	const cur = getSiteCurrency();
	const withoutDiscount = `<p style="font-size: 24px; ">${product.price}${cur}</p>`;
	const withDiscount = `
	<div style="display: flex;flex-direction:column;  align-items:center;">
		<p style="font-size: 24px; text-decoration: line-through;">${product.price}${cur}</p>
		<p style="font-size: 24px; font-weight:bold; color: red;">${calcPriceAfterDiscount(
			product.price,
			product.discount,
		)}${cur}</p>
	</div>`;

	const html = `
	<div style="display: flex; flex-direction: column; gap:4px; align-items: center;">
		<h1 style="font-size: 48px;">${site_name}</h1>
		${product.discount ? withDiscount : withoutDiscount}
	</div>
	`;
	printJS({
		printable: html,
		type: 'raw-html',
		style: '*{margin:0;padding:0; font-family:sans-serif} html {direction: rtl;}',
	});
};
