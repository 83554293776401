import {Stack} from '@mui/material';
import React from 'react';
import {paymentMethods, paymentStatus} from '../../../components/payments/paymentMethods';
import Row from '../../../components/ui/Row';
import CopyButton from 'src/components/buttons/CopyButton';
import {useSelector} from 'react-redux';
import {getUtcDateTimeFromISO} from 'src/utils/date';
import {getAddress} from 'src/utils/orderUtils';

const PaymentInfo = ({payment}) => {
	if (payment?.gateway === 'cash') return;
	return (
		<>
			<Row
				label="طريقة الدفع"
				value={payment?.gateway ? paymentMethods[payment.gateway].label : paymentMethods.cash.label}
			/>
			{payment && payment.gateway === 't_lync' && (
				<>
					<Row label="حالة الدفع" value={paymentStatus[payment.status]?.ar ?? ''} />
					{payment.payload?.redirect_to_url && payment.status != 'confirmed' && (
						<Row
							label="رابط الدفع"
							value={<CopyButton label="نسخ الرابط" value={payment.payload.redirect_to_url} />}
						/>
					)}
				</>
			)}
		</>
	);
};
const bgcolor = '#EDEEF4';

export default function OrderAdditionalDetails({order}) {
	const deliveryType = order?.delivery_info?.type ?? 'delivery';
	const {host} = useSelector((state) => state.admin.user.OwnedWebsite);

	return (
		<>
			<Row
				bgcolor={bgcolor}
				label="تاريخ انشاء الطلب"
				value={getUtcDateTimeFromISO(order.created_at)}
			/>
			{order.selected_date && (
				<Row label="تاريخ التسليم" value={getUtcDateTimeFromISO(order.selected_date)} />
			)}
			<PaymentInfo payment={order.payment} />
			<Row
				bgcolor={bgcolor}
				label="رابط التتبع"
				value={
					<Stack direction={'row'} alignItems="center">
						<a
							style={{
								textDecoration: 'none',
								color: '#02393E',
								textAlign: 'end',
								display: 'block',
								fontSize: 14,
							}}
							target="_blank"
							href={`https://${host}/orders/${order?.uuid}`}
							rel="noreferrer"
						>
							عرض صفحة تتبع الطلب
						</a>
						<CopyButton value={`https://${host}/orders/${order?.uuid}`} />
					</Stack>
				}
			></Row>
			{deliveryType === 'delivery' && (
				<Row label="عنوان التوصيل" value={order.full_address || getAddress(order, true)} />
			)}
			{order.delivery_info?.optionName && (
				<Row
					bgcolor={bgcolor}
					label="خيار الاستلام/التوصيل"
					value={
						deliveryType === 'delivery'
							? `${order.delivery_info?.optionName || 'لم يحدد'}`
							: `${order.delivery_info?.optionName || 'لم يحدد'}`
					}
				/>
			)}
		</>
	);
}
