// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// css
import './App.css';
// components
import {Provider} from 'react-redux';
// Create rtl cache
import {QueryClient, QueryClientProvider} from 'react-query';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from 'src/redux-store';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import RTL from './theme/RTL';
import {useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import {storeAdminToken} from './utils/storage';

////////////////////////////////////////

const reactQueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});
export default function App() {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const token = searchParams.get('token');
		if (token != null) {
			storeAdminToken(token);
			window.location.href = '/';
		}
	}, []);

	return (
		<QueryClientProvider client={reactQueryClient}>
			<Provider store={store}>
				{/* <StylesProvider jss = {jss} > */}
				<ThemeConfig>
					<RTL>
						<ToastContainer
							position="top-center"
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<Router />
						<ServiceWorkerWrapper />
					</RTL>
				</ThemeConfig>
				{/* </StylesProvider> */}
			</Provider>
		</QueryClientProvider>
	);
}
