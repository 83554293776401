import {useQuery} from 'react-query';
import {getShippingProviders} from 'src/utils/AdminApi';

const useShippingProviders = (options = {}) => {
	return useQuery('getshippingproviders', getShippingProviders, {
		refetchOnWindowFocus: false,
		...options,
	});
};

export default useShippingProviders;
