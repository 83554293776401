import {handleRequestError, isNullOrEmpty} from '../helperMethods';

/* 
    this function take axios error and return descriptive message
*/
export const handleAccessError = function (provider, error) {
	// 400 invalid username/password - blocked
	// 406 not enabled or not configured correctly
	// 5xx errors from there server
	let errorMessage = 'حدث خطا غير متوقع';
	if (error.response) {
		const {status, data: body} = error.response;

		if (status === 406) {
			// this function will try to extract the message from error response
			return [status, handleRequestError(error)];
		} else if (status === 401 || status === 403) {
			return [
				status,
				'ليس لديك صلاحية للقيام بهذه العملية, يرجي التاكد من اعدادات الربط',
				'unauthorized',
			];
		} else if (status === 400) {
			const messages = body?.messages;
			if (isNullOrEmpty(messages)) return [status, 'حدث خطأ غير متوقع'];
			const isBlocked = messages[0].message?.toLowerCase().includes('blocked');
			if (isBlocked) {
				return [status, 'تم حظر حسابك في درب السبيل، يرجي مراجعة الشركة'];
			}
			const wrongCredentials = messages[0].param === 'password';
			if (wrongCredentials) return [status, 'اسم المستخدم او كلمة المرور غير صحيحة', 'unauthorized'];

			return [status, 'يوجد مشكلة في البيانات المدخلة', 'invalid_inputs'];
			// input validation erros (in case of creating order)
			// we need a way to figure out what are the fields that causing error with translation
			// we don't show the user request fields : we need something like
			/* 
                [
                    "يرجي ادخال المدينة"
                    "يرجي ادخال المنطقة"
                ]
            */
		}
		return [status, errorMessage];
	}
	// this in case if we couldn't find error response
	return [0, 'يوجد مشكلة في الاتصال بالسيرفر'];
};
export const handleLoginError = function (provider, error) {
	// 400 invalid username/password - blocked
	// 406 not enabled or not configured correctly
	// 5xx errors from there server
	let errorMessage = 'حدث خطا غير متوقع';
	if (error.response) {
		const {status, data: body} = error.response;

		if (status === 406) {
			// this function will try to extract the message from error response
			return [status, handleRequestError(error)];
		} else if (status === 401 || status === 403) {
			return [status, 'اسم المستخدم او كلمة المرور غير صحيحة', 'unauthorized'];
		} else if (status === 400) {
			const messages = body?.messages;
			const message = body?.message;

			if (isNullOrEmpty(messages)) return [status, message ?? 'حدث خطأ غير متوقع'];
			const isBlocked = messages[0].message?.toLowerCase().includes('blocked');
			if (isBlocked) {
				return [status, 'تم حظر حسابك في درب السبيل، يرجي مراجعة الشركة'];
			}
			const wrongCredentials = messages[0].param === 'password';
			if (wrongCredentials) return [status, 'اسم المستخدم او كلمة المرور غير صحيحة', 'unauthorized'];

			return [status, 'يوجد مشكلة في البيانات المدخلة', 'invalid_inputs'];
			// input validation erros (in case of creating order)
			// we need a way to figure out what are the fields that causing error with translation
			// we don't show the user request fields : we need something like
			/* 
                [
                    "يرجي ادخال المدينة"
                    "يرجي ادخال المنطقة"
                ]
            */
		}
		return [status, errorMessage];
	}
	// this in case if we couldn't find error response
	return [0, 'يوجد مشكلة في الاتصال بالسيرفر'];
};

export const handleActionAccessError = function (provider, error) {
	// 400 invalid username/password - blocked
	// 406 not enabled or not configured correctly
	// 5xx errors from there server
	let errorMessage = 'حدث خطا غير متوقع';
	if (error.response) {
		const {status, data: body} = error.response;

		if (status === 406) {
			// this function will try to extract the message from error response
			return [status, handleRequestError(error)];
		} else if (status === 401 || status === 403) {
			return [
				status,
				'ليس لديك صلاحية للقيام بهذه العملية, يرجي التاكد من اعدادات الربط',
				'unauthorized',
			];
		} else if (status === 400) {
			const messages = body?.messages;
			if (isNullOrEmpty(messages)) return [status, 'حدث خطأ غير متوقع'];
			const isBlocked = messages[0].message?.toLowerCase().includes('blocked');
			if (isBlocked) {
				return [status, 'تم حظر حسابك في درب السبيل، يرجي مراجعة الشركة'];
			}
			const wrongCredentials = messages[0].param === 'password';
			if (wrongCredentials)
				return [
					401,
					'ليس لديك صلاحية للقيام بهذه العملية, يرجي التاكد من اعدادات الربط',
					'unauthorized',
				]; // we added this so when some one create order with wrong credental then redirect him to setup provider page

			return [status, 'يوجد مشكلة في البيانات المدخلة', 'invalid_inputs'];
			// input validation erros (in case of creating order)
			// we need a way to figure out what are the fields that causing error with translation
			// we don't show the user request fields : we need something like
			/* 
                [
                    "يرجي ادخال المدينة"
                    "يرجي ادخال المنطقة"
                ]
            */
		}
		return [status, body.message ?? errorMessage];
	}
	// this in case if we couldn't find error response
	return [0, 'يوجد مشكلة في الاتصال بالسيرفر'];
};
