import {Button, Card, Divider, Stack, Typography} from '@mui/material';
import React from 'react';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getNumber, getPriceNumber} from 'src/utils/numberUtils';
import {getTotal} from 'src/utils/orderUtils';
import {getSiteCurrency} from 'src/utils/selectors';
import Row from 'src/components/ui/Row';
import {canAccess} from 'src/utils/permissions';
import _ from 'lodash';
const bgcolor = '#EDEEF4';

/**
 *
 * @param {object} param0
 * @param {import('src/types/contentTypes').ContentTypeAttributes<'orders'>} param0.order
 * @returns
 */
export default function OrderDetailsSummury({order, receivePayment}) {
	const currency = getSiteCurrency();
	// let {oldTotal, newTotal} = getTotal(order);
	let oldTotal = getPriceNumber(order.oldSubTotal);
	let newTotal = getPriceNumber(order.subTotal);
	const shouldShowTotal = true; //  currency === 'د.ل';

	return (
		<Stack
			sx={{bgcolor: '#FAFAFA', p: 2, border: 'solid 0.3px #D9DBE4', borderRadius: 3}}
			flex={1}
			spacing={1.25}
		>
			<Typography variant="h6">ملخص الطلب</Typography>

			<Row label="مجموع المشتريات" value={`${newTotal} ${currency}`} />
			{!isNullOrEmpty(order.delivery_price) && (
				<Row label="سعر التوصيل" value={`${getPriceNumber(order.delivery_price)} ${currency}`} />
			)}
			<Divider />
			{order.discount > 0 && <Row label="التخفيض" value={`-${order.discount} ${currency}`} />}

			{shouldShowTotal && (
				<Row
					labelVariant="subtitle1"
					valueVariant="subtitle1"
					valueColor="#297f5a"
					label="الاجمالي"
					bgcolor={bgcolor}
					value={`${getPriceNumber(order.total)} ${currency}`}
				/>
			)}
			<Stack>
				{order.down_payment > 0 && <Row label="العربون" value={`-${order.down_payment} ${currency}`} />}
				{order.down_payment > 0 && (
					<Row
						labelVariant="subtitle1"
						valueVariant="subtitle1"
						valueColor="#297f5a"
						bgcolor={bgcolor}
						label="المتبقي"
						value={`${getPriceNumber(order.total - order.down_payment)} ${currency}`}
					/>
				)}
				{order.payment_received && order.status != 'returned' && (
					<Typography mt={1} color="primary" textAlign="center">
						تم الاستلام
					</Typography>
				)}
			</Stack>

			{!order.is_cashier && !order.payment_received && order.status != 'returned' && (
				<Button variant="outlined" onClick={() => receivePayment(true)}>
					تأكيد الاستلام
				</Button>
			)}
			{!order.is_cashier && canAccess('orders.updatePaymentStatus') && order.payment_received && (
				// don't show this option if order is made from cashier page ( we want to use (return, exchange) actions to handle it )
				<Button variant="outlined" onClick={() => receivePayment(false)}>
					الغاء الاستلام
				</Button>
			)}
		</Stack>
	);
}
