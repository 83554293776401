import {Button, Stack, TextField} from '@mui/material';
import {useState} from 'react';
import {toast} from 'react-toastify';
import {showModel} from 'src/components/modals/showModel';
import {manualUpdateProductQuantity} from 'src/utils/AdminApi';
import {handleRequestError} from 'src/utils/helperMethods';
import {isNumber} from 'src/utils/numberUtils';

export default function UpdateProductQtyModal({
	// function to close the modal
	dismiss,
	onSuccess,
	productId,
	variationId,
}) {
	const [qty, setQty] = useState();
	const [desc, setDesc] = useState('');
	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();
				try {
					await manualUpdateProductQuantity({
						productId,
						variationId,
						newQuantity: qty,
						description: desc,
					});
					dismiss();
					onSuccess();
				} catch (err) {
					toast.error(handleRequestError(err));
				}
			}}
		>
			<Stack spacing={6}>
				<Stack spacing={1}>
					<TextField
						label="الكمية"
						autoComplete="off"
						sx={{flex: '1 1 auto'}}
						type="number"
						value={qty ?? ''}
						onChange={(e) => +e.target.value >= 0 && setQty(e.target.value)}
					/>
					<TextField
						fullWidth
						type="text"
						minRows={2}
						multiline
						label="تفاصيل / سبب التعديل"
						onChange={(e) => setDesc(e.target.value)}
						value={desc}
					/>
				</Stack>
				<Button type="submit" disabled={!isNumber(qty)} variant="contained">
					تحديث
				</Button>
			</Stack>
		</form>
	);
}

export function showUpdateProductQtyModal({onSuccess, productId, variationId}) {
	return showModel({
		title: 'تعديل الكمية',
		component: (
			<UpdateProductQtyModal onSuccess={onSuccess} productId={productId} variationId={variationId} />
		),
	});
}
