import {
	Button,
	Card,
	Container,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import Page from 'src/components/Page';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import {useState} from 'react';
import {Container as DContainer, Draggable} from 'react-smooth-dnd';
import {toast} from 'react-toastify';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog.js';
import PlusButton from 'src/components/buttons/PlusButton';
import useCategories from 'src/hooks/useCategories';
import {useDndScrollWorkaround} from 'src/hooks/useDndScrollWorkaround';
import {deleteCategory as deleteCategoryRequest, reorderCategory} from 'src/utils/AdminApi';
import {defaultImageSrc} from 'src/utils/productUtils';
import CreateCategoryForm from './CreateCategory.form';
import UpdateCategoryForm from './UpdateCategory.form';
import {getImageFormat} from 'src/utils/imageUtils';
import StyledLink from 'src/components/ui/StyledLink';
import NavButton from 'src/components/NavButton';
import {arrayMoveImmutable} from 'src/utils/dndUtils';

export default function Categories() {
	const [addDrawerVisible, setAddDrawerVisibility] = useState(false);
	const [categoryToEdit, setCategoryToEdit] = useState();
	const {categories, updateCategory, deleteCategory, setCategories, addCategory} = useCategories();
	useDndScrollWorkaround();

	const onDrop = async ({removedIndex, addedIndex, ...other}) => {
		const categoryId = categories[removedIndex]?.id;
		const newPosition = categories[addedIndex]?.row_order;
		if (categoryId == null || newPosition == null) return;
		setCategories(arrayMoveImmutable(categories, removedIndex, addedIndex));
		try {
			const res = await reorderCategory(categoryId, newPosition);
			// we update state with updated row_order from database
			setCategories(res);
		} catch (err) {
			toast.error('حدث خطا غير متوقع');
		}
	};

	const openEdit = (cat) => {
		setCategoryToEdit(cat);
	};
	return (
		<Page title="الفئات">
			<Container maxWidth="md">
				<Card sx={{p: 2}}>
					<Stack sx={{mb: 1, mx: 2}} justifyContent="space-between" alignItems="center" direction="row">
						<Typography mt={1} variant="h4">
							التصنيفات
						</Typography>

						<PlusButton
							onClick={() => {
								setAddDrawerVisibility(true);
							}}
						/>
					</Stack>

					<StyledLink to={`/categories-stats`}>
						<Typography mx={2}>عرض تقارير حسب التصنيفات</Typography>
					</StyledLink>

					<List>
						<DContainer
							lockAxis="y"
							// onDragStart={() => {}}
							dragHandleSelector=".drag-handle"
							onDrop={onDrop}
						>
							{categories.map((cat) => (
								<Draggable key={cat.id}>
									<ListItem>
										<ListItemButton className="drag-handle">
											<ListItemIcon>
												<img
													loading="lazy"
													src={getImageFormat(cat.image)}
													width={30}
													height={30}
													style={{marginLeft: 4}}
												/>
											</ListItemIcon>
											<ListItemText
												primary={
													<StyledLink to={`/categories-stats?categoryId=${cat.id}`}>{cat.name}</StyledLink>
												}
											/>

											<ListItemIcon>
												<NavButton variant="text" text="المنتجات" to={`/products?categoryId=${cat.id}`} />
											</ListItemIcon>
											<ListItemIcon>
												<Button onClick={() => openEdit(cat)} variant="text">
													تعديل
												</Button>
											</ListItemIcon>
											<ListItemIcon>
												<DeleteAlertDialog
													onDelete={() => {
														deleteCategoryRequest(cat.id);
														deleteCategory(cat.id);
													}}
												/>
											</ListItemIcon>
											<ListItemIcon>
												<DragHandleIcon />
											</ListItemIcon>
										</ListItemButton>
									</ListItem>
									<Divider />
								</Draggable>
							))}
						</DContainer>
					</List>

					{/* <DContainer onDrop={onDrop}>
					<Grid container spacing={3}>
						{categories.map((cat) => (
							<Grid key={cat.id} item xs={6} sm={4} md={2}>
								<Draggable>
									<Card sx={{p: 1}}>
										<Stack justifyContent="center" alignItems="center" spacing={2}>
											<Stack
												direction="row"
												sx={{
													flexGrow: 1,
													alignSelf: 'stretch',
												}}
												justifyContent="space-between"
											>
											
											</Stack>
											<CatImgStyle src={cat.image?.url} />
											<Typography sx={{paddingBottom: 6}}>{cat.name}</Typography>
										</Stack>
									</Card>
								</Draggable>
							</Grid>
						))}
					</Grid>
				</DContainer> */}
				</Card>
			</Container>
			<Drawer
				open={addDrawerVisible}
				onClose={() => {
					setAddDrawerVisibility(false);
				}}
				PaperProps={{
					sx: {
						width: {xs: '100%', sm: '50%', md: '30%'},
						padding: 4,
						border: 'none',
						overflow: 'hidden',
					},
				}}
			>
				<Stack sx={{mb: 3}} direction="row" alignItems="center" justifyContent="space-between">
					<Typography variant="h4">اضافة فئة</Typography>

					<Button
						onClick={() => {
							setAddDrawerVisibility(false);
						}}
						variant="outlined"
					>
						x
					</Button>
				</Stack>
				<CreateCategoryForm
					categories={categories}
					onSuccess={(newCategory) => {
						setAddDrawerVisibility(false);
						addCategory(newCategory);
					}}
				/>
			</Drawer>
			<Drawer
				open={categoryToEdit ? true : false}
				onClose={() => {
					setCategoryToEdit(null);
				}}
				PaperProps={{
					sx: {
						width: {xs: '100%', sm: '50%', md: '30%'},
						padding: 4,
						border: 'none',
						overflow: 'hidden',
					},
				}}
			>
				<Stack sx={{mb: 3}} direction="row" alignItems="center" justifyContent="space-between">
					<Typography variant="h4">تعديل فئة</Typography>

					<Button
						onClick={() => {
							setCategoryToEdit(null);
						}}
						variant="outlined"
					>
						x
					</Button>
				</Stack>
				<UpdateCategoryForm
					categories={categories.filter((cat) => cat.id != categoryToEdit?.id)}
					category={categoryToEdit}
					onSuccess={(newCategory) => {
						updateCategory(newCategory);
						setCategoryToEdit(null);
					}}
				/>
			</Drawer>
		</Page>
	);
}
