// react
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
// form handling
import {Form, FormikProvider, useFormik} from 'formik';
// material
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Card,
	Chip,
	Container,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
// components
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog.js';
import Dropzone from '../../components/inputs/dropzone';
// api
import {
	deleteProduct,
	getProduct,
	getProductInventoryChanges,
	getProperties,
	updateProduct,
} from '../../utils/AdminApi';

//redux
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import NavButton from 'src/components/NavButton';
import Page from 'src/components/Page';
import AddPropertyValueModal from 'src/components/_dashboard/properties/AddPropertyValueModal';
import SelectPropertyModal from 'src/components/_dashboard/properties/SelectPropertyModal';
import SubmitButton from 'src/components/buttons/SubmitButton';
import CustomFields from 'src/components/inputs/CustomFields';
import ImageCropPicker from 'src/components/inputs/ImageCropPicker.js/ImageCropPicker';
import Picker from 'src/components/inputs/Picker';
import {ScrollToFieldError} from 'src/hooks/ScrollToFieldError';
import useCategories from 'src/hooks/useCategories';
import useFind from 'src/hooks/useFind';
import DiscountPicker from 'src/pages/products/common/DiscountPicker';
import PublishSwitch from 'src/pages/products/common/PublishSwitch';
import {
	addProductPermission,
	deleteProductPermission,
	findOneProductPermission,
	updateProductPermission,
} from 'src/permissionsList';
import {isNullOrEmpty, updateArray} from 'src/utils/helperMethods';
import {canAccess} from 'src/utils/permissions';
import {getGroupedPropertiesFromVariations, getVariationOptions2} from 'src/utils/variationUtils';
import AvailabilityRadioButtons from './common/AvailabilityRadioButtons';
import VariationsForm from './create/VariationsForm';
//query
import {useQuery} from 'react-query';
import {updateProductSchema} from './product.schema';
import {getImageSrc} from 'src/utils/productUtils';
import ProductPurchaseItemRecord from './common/ProductPurchaseItemRecord';
import ProductBarcodeGenerator from './ProductBarcodeGenerator';
import {accountIs} from 'src/utils/siteUtils';
import {printJsonReceipt, printProductPrice} from 'src/features/printer';
import {showModel} from 'src/components/modals/showModel';
import {showUpdateProductQtyModal} from './common/UpdateProductQtyModal';
import {getNumber} from 'src/utils/numberUtils';

export default function UpdateProduct() {
	const navigate = useNavigate();
	const [product, setProduct] = useState();
	const [selectedVariations, setSelectedVariations] = useState([]);
	const [selectedImages, setSelectedImages] = useState([]);
	const [openedQuantityChange, setOpenedQuantityChange] = useState(false);
	const {categories} = useCategories();
	const {host} = useSelector((state) => state.admin.user.OwnedWebsite);
	// Variations states ------------------------------------------------------------
	const [allSiteProperties, setProperties] = useFind(getProperties);
	const [selectedProperties, setSelectedProperties] = useState([]);
	// theese will be used to add new options value to existing options (properties)
	const [pvModalIsVisible, setPvModalVisibility] = useState(null);
	const [propertyToAddTo, setPropertyToAddTo] = useState(null);

	const onNewPropertyValueAdded = (newPropertyValue) => {
		const propertyToUpdateIndex = allSiteProperties.findIndex((p) => p.id === propertyToAddTo);
		const _allSiteProperties = [...allSiteProperties];
		_allSiteProperties[propertyToUpdateIndex].values.push(newPropertyValue);
		setProperties(_allSiteProperties);
		// reset
		setPvModalVisibility(false);
		setPropertyToAddTo(null);
	};
	// ------------------------------------------------------------
	const isSelectedOption = (selectedProperty, propertyValueId) => {
		return selectedProperty.values.find((s) => s.id === propertyValueId);

		// ||
		// selectedVariations.some((vars) =>
		// 	vars.values.find((pv) => pv === propertyValueId),
		// )
	};
	let {id} = useParams();
	const canSubmit = canAccess(updateProductPermission);

	const {data, refetch} = useQuery(
		'product',
		() => {
			return getProduct(id);
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				setProduct(data);
				setSelectedProperties(getGroupedPropertiesFromVariations(data.variations));
			},
		},
	);

	const {data: productInventoryChanges = [], refetch: refetchInventoryChanges} = useQuery(
		['product-inventory-changes', id],
		() => getProductInventoryChanges(id),
		{
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		// this logic if variations is not empty
		// we need to restore delete state for old variants after create new options
		if (isNullOrEmpty(product)) return;
		const newVariations = getVariationOptions2(selectedProperties);
		newVariations.forEach((vars) => {
			const {
				deleted = false,
				price,
				quantity,
				discount,
			} = selectedVariations.find((v) => v.name === vars.name) ?? {};
			const existBefore = product.variations.find((v) => v.name === vars.name);
			vars.deleted = deleted ?? false;
			// if true then we will use it to make post request
			vars.isNew = existBefore == null;
			vars.id = existBefore?.id;
			vars.quantity = existBefore?.quantity ?? 0;
			vars.price = price ?? existBefore?.price;
			vars.discount = discount ?? existBefore?.discount;
		});
		// find variations deleted from old variations
		product?.variations.forEach((vars) => {
			const notExistAnyMore = newVariations.every((v) => v.name !== vars.name);
			if (notExistAnyMore)
				newVariations.push({
					...vars,
					deleted: true,
				});
		});

		setSelectedVariations(
			newVariations,
			// newVariations.sort((a, b) => {
			// 	if (!a.id && !b.id) return 0;
			// 	if (!a.id && b.id) return 1;
			// 	if (a.id && !b.id) return -1;
			// 	if (a.id === b.id) return 0;
			// 	if (a.id > b.id) return -1;
			// 	if (a.id < b.id) return 1;
			// }),
		);
	}, [selectedProperties]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			...product,
			attrs: product?.attrs ?? [],
			inputs: product?.inputs ?? [],
			custom_fields: product?.custom_fields ?? {},
			categories: product?.categories?.map((cat) => cat.id) ?? [],
			mainImage: null,
		},
		validationSchema: updateProductSchema,
		onSubmit: async (v) => {
			if (!canSubmit) return;
			const {mainImage, ...p} = v;

			await toast.promise(
				updateProduct(
					id,
					{
						...p,
						variations: selectedVariations.map((v) => ({
							id: v.id,
							name: v.name,
							values: v.values.map((v) => v.id),
							product: product.id,
							price: isNullOrEmpty(v.price) ? product.price : v.price,
							// quantity: isNullOrEmpty(v.quantity) ? 0 : v.quantity,
							cost_per_unit: v.cost_per_unit,
							addedQuantity: v.addedQuantity,
							discount: v.discount,
							isDeleted: v.deleted,
						})),
					},
					mainImage,
					selectedImages,
				),
				{
					error: 'حدث خطا غير متوقع',
					success: 'تم تحديث المنتج بنجاح',
					pending: 'يرجي الانتظار',
				},
			);
			// refetch product data from api to fetch updates
			const res = await refetch();
			// reset variation form input
			setSelectedVariations(res?.data?.variations ?? []);
			// toggle off quantity update form
			setOpenedQuantityChange(false);
			refetchInventoryChanges();
		},
	});

	const {
		errors,
		touched,
		values,
		isSubmitting,
		handleSubmit,
		getFieldProps,
		setFieldTouched,
		setFieldValue,
	} = formik;

	const getHelperProps = (attr) => ({
		error: Boolean(touched[attr] && errors[attr]),
		helperText: touched[attr] && errors[attr],
		onWheel: (e) => e.target.blur(),
	});

	return (
		<Page title="تعديل المنتج">
			<AccessControlWrapper controllerAction={updateProductPermission} or={findOneProductPermission}>
				<Container>
					<FormikProvider value={formik}>
						<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
							<Stack sx={{mb: 2}} spacing={3}>
								<Stack
									// sx={{mb: 4}}
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Stack>
										<Typography variant="h4">تعديل المنتج</Typography>
										<a
											style={{color: '#02393E'}}
											target="_blank"
											href={`https://${host}/products/${id}`}
											rel="noreferrer"
										>
											عرض المنتج
										</a>
									</Stack>

									<Stack direction="row" gap={2}>
										{canAccess(deleteProductPermission) && (
											<DeleteAlertDialog
												onDelete={async () => {
													try {
														await toast.promise(deleteProduct(product.id), {
															error: 'حدث خطأ غير متوقع',
															success: 'تم حذف المنتج بنجاح',
															pending: 'يرجي الانتظار',
														});
														navigate('/products', {replace: true});
													} catch (err) {
														//
													}
												}}
											/>
										)}
									</Stack>
								</Stack>

								<Stack
									direction={{md: 'row', xs: 'column'}}
									alignItems={{md: 'flex-start', xs: 'stretch'}}
									spacing={3}
								>
									{/* Main info Card */}
									<Card sx={{p: 4, flex: 1, maxWidth: {lg: 600}}}>
										<Stack spacing={2}>
											<ScrollToFieldError />

											<Stack
												direction={{md: 'row', xs: 'column-reverse'}}
												// alignItems={{md: 'flex-start', xs: 'stretch'}}
												spacing={3}
											>
												{product && (
													<ImageCropPicker
														maxSize={3}
														placeholder={'الصورة الرئيسية'}
														defaultImage={getImageSrc(product)}
														{...getHelperProps('mainImage')}
														setFile={(file) => formik.setFieldValue('mainImage', file)}
													/>
												)}
												<Stack flex={1} justifyContent={'space-between'} spacing={2}>
													<TextField
														fullWidth
														type="text"
														label="اسم المنتج"
														{...getFieldProps('name')}
														{...getHelperProps('name')}
														value={values?.name ? values.name : ''}
													/>

													<TextField
														fullWidth
														type="text"
														minRows={4}
														multiline
														label="تفاصيل المنتج"
														{...getFieldProps('details')}
														{...getHelperProps('details')}
														value={values?.details ? values.details : ''}
													/>
												</Stack>
											</Stack>

											{categories.length > 0 && (
												<Picker
													size="small"
													multiple
													{...getFieldProps('categories')}
													{...getHelperProps('categories')}
													label="الفئة"
													options={categories}
													labelKey="name"
													valueKey="id"
													value={values.categories}
													onChange={(e) => {
														setFieldValue('categories', e.target.value);
													}}
												/>
											)}
											<AvailabilityRadioButtons
												value={values.infinite}
												onChange={(newValue) => setFieldValue('infinite', newValue)}
											/>
											{isNullOrEmpty(product?.variations) && (
												<>
													<Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
														<TextField
															fullWidth
															type="text"
															pattern="\d*"
															InputProps={{min: 0}}
															label="السعر"
															{...getFieldProps('price')}
															{...getHelperProps('price')}
															value={values?.price != null ? values.price : ''}
														/>
														<DiscountPicker
															sx={{maxWidth: 90}}
															name="discount"
															value={values.discount ?? 0}
															onChange={(discountValue) => {
																setFieldValue('discount', discountValue);
															}}
														/>
														<IconButton size="small" onClick={() => printProductPrice(values)}>
															<PrintRoundedIcon fontSize="small" />
														</IconButton>
													</Stack>
												</>
											)}

											{/* quantity update */}
											{isNullOrEmpty(product?.variations) && !values.infinite && (
												<>
													<Stack px={1} py={1} border={'1px solid #dbdee0'} borderRadius={1} spacing={2}>
														<Stack direction={'row'} spacing={1} alignItems={'center'}>
															<Typography flexGrow={1}>الكمية: {values.quantity}</Typography>

															{canAccess() && (
																<Button
																	onClick={(e) =>
																		showUpdateProductQtyModal({
																			productId: product.id,
																			onSuccess: () => {
																				refetch();
																				refetchInventoryChanges();
																			},
																		})
																	}
																	sx={{color: 'gray'}}
																>
																	تعديل
																</Button>
															)}

															<Button
																size={'small'}
																variant="outlined"
																color={openedQuantityChange ? 'error' : 'primary'}
																// to make button as small as possible
																sx={{minWidth: '1px'}}
																onClick={() => {
																	// clear input after cancel update
																	setFieldValue('addedQuantity', undefined);
																	setFieldValue('cost_per_unit', undefined);
																	setOpenedQuantityChange(!openedQuantityChange);
																}}
															>
																{openedQuantityChange ? 'إلغاء' : 'إضافة'}
															</Button>
														</Stack>

														<Stack display={!openedQuantityChange && 'none'} spacing={1}>
															<Typography variant="body2">ستضاف الكمية المطلوبة الى المشتريات.</Typography>
															<Stack direction={{md: 'row'}} spacing={{xs: 1, md: 2}}>
																<TextField
																	id="addedQuantity"
																	name="addedQuantity"
																	label="الكمية المطلوبة"
																	autoComplete="off"
																	sx={{flex: '1 1 auto'}}
																	type="number"
																	{...getFieldProps('addedQuantity')}
																	{...getHelperProps('addedQuantity')}
																	value={values.addedQuantity ?? ''}
																/>
																<TextField
																	name="cost_per_unit"
																	label="سعر القطعة"
																	autoComplete="off"
																	sx={{flex: '1 1 auto'}}
																	type="number"
																	{...getFieldProps('cost_per_unit')}
																	{...getHelperProps('cost_per_unit')}
																	value={values.cost_per_unit ?? ''}
																/>
															</Stack>
														</Stack>
													</Stack>

													<ProductPurchaseItemRecord
														quantity={values.quantity}
														refetch={() => {
															refetchInventoryChanges();
															refetch();
														}}
														items={productInventoryChanges}
													/>
												</>
											)}

											{/* {Boolean(product) && (
												<ProductQuantityAdder
													product={product}
													setProduct={setProduct}
													productFormikQuantity={values.quantity}
													setFieldValue={setFieldValue}
													selectedVariations={selectedVariations}
													setSelectedVariations={setSelectedVariations}
												/>
											)} */}

											{/* Properties selection, i removed it so that user can't change product type, but we may return it back if users want this */}
											{/* {isNullOrEmpty(product?.variations) && (
												<Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
													<Typography>هل هذا المنتج يحتوي علي اختيارات مثل المقاس واللون ؟</Typography>
													<SelectPropertyModal
														properties={allSiteProperties}
														setProperties={setProperties}
														onPropertiesSelected={(newProperties) =>
															setSelectedProperties([...selectedProperties, ...newProperties])
														}
														propertiesNotSelected={allSiteProperties.filter(
															(s) => !selectedProperties.find((sp) => sp.id === s.id),
														)}
													/>
												</Stack>
											)} */}

											{selectedProperties.map((selectedProperty, selectedPropertyIndex) => {
												return (
													<div key={selectedProperty.id}>
														<Stack spacing={1} direction="row" justifyContent="space-between">
															<Typography>{selectedProperty.name}</Typography>
														</Stack>
														{/* we need to check if property value exist in selected properties.values */}
														{/* list of property values */}
														<Stack flexWrap="wrap" gap={1} direction="row">
															{allSiteProperties
																.find((p) => p.id === selectedProperty.id)
																?.values.map((propertyValue) => (
																	<Chip
																		color="primary"
																		onClick={() => {
																			if (isSelectedOption(selectedProperty, propertyValue.id)) {
																				if (selectedProperty.values.length <= 1) return;
																				// we need to delete value from values array
																				const _selectedProperties = [...selectedProperties];
																				_selectedProperties[selectedPropertyIndex].values = _selectedProperties[
																					selectedPropertyIndex
																				].values.filter((s) => s.id !== propertyValue.id);

																				setSelectedProperties(_selectedProperties);
																				//
																			} else {
																				// this to reset all deleted variation that contain checked value

																				setSelectedVariations(
																					selectedVariations.map((vars) => {
																						if (vars.values.find((vv) => vv.id === propertyValue.id))
																							return {...vars, deleted: false};
																						return vars;
																					}),
																				);
																				setSelectedProperties(
																					updateArray(
																						selectedProperties,
																						{
																							...selectedProperty,
																							values: [
																								...selectedProperty.values,
																								{...propertyValue, property: selectedProperty},
																							],
																						},
																						'id',
																					),
																				);
																			}
																		}}
																		variant={
																			isSelectedOption(selectedProperty, propertyValue.id) ? 'filled' : 'outlined'
																		}
																		key={propertyValue.id}
																		value={propertyValue.id}
																		label={propertyValue.value}
																	/>
																))}
															<Chip
																onClick={() => {
																	setPvModalVisibility(true);
																	setPropertyToAddTo(selectedProperty.id);
																}}
																variant="outlined"
																label="+ اضافة خيار جديد"
															/>
														</Stack>
													</div>
												);
											})}

											<Divider />

											{!isNullOrEmpty(selectedVariations) && (
												<VariationsForm
													productInventoryChanges={productInventoryChanges}
													showSort
													variations={selectedVariations}
													setVariations={setSelectedVariations}
													values={values}
													productId={product.id}
													refetch={() => {
														refetchInventoryChanges();
														refetch();
													}}
												/>
											)}
										</Stack>

										<br />
										<PublishSwitch
											value={values.publish_at != null}
											onChange={(newValue) => setFieldValue('publish_at', newValue)}
										/>
									</Card>
									<Card sx={{p: 4, flex: 1}}>
										<Accordion>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography>معلومات اضافية</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Stack spacing={2}>
													<Typography>صور اضافية للمنتج</Typography>
													{product && (
														<Dropzone
															maxSize={1}
															allowMultiple
															images={values?.images}
															onImagesChanged={(images) => {
																setFieldValue('images', images);
															}}
															selectedFiles={selectedImages}
															onFilesChanged={setSelectedImages}
														/>
													)}
													<CustomFields
														title={'جدول المواصفات '}
														errors={errors.attrs}
														touches={touched.attrs}
														value={values?.attrs ?? []}
														onChange={(newValue) => formik.setFieldValue('attrs', newValue)}
														setFieldTouched={setFieldTouched}
													/>

													<TextField
														fullWidth
														InputProps={{min: 0}}
														pattern="\d*"
														type="text"
														inputMode="numeric"
														label="الوزن"
														placeholder="الوزن بالكيلو"
														{...getFieldProps('weight')}
														{...getHelperProps('weight')}
														value={values.weight ? values.weight : ''}
													/>
													<Stack spacing={1} direction="row" justifyContent="space-between">
														<TextField
															fullWidth
															InputProps={{min: 0}}
															pattern="\d*"
															type="text"
															inputMode="numeric"
															label="الطول"
															placeholder="الطول"
															{...getFieldProps('width')}
															{...getHelperProps('width')}
															value={values.width ? values.width : ''}
														/>
														<TextField
															fullWidth
															InputProps={{min: 0}}
															pattern="\d*"
															type="text"
															inputMode="numeric"
															label="العرض"
															placeholder="العرض"
															{...getFieldProps('height')}
															{...getHelperProps('height')}
															value={values.height ? values.height : ''}
														/>
														<TextField
															fullWidth
															InputProps={{min: 0}}
															pattern="\d*"
															type="text"
															inputMode="numeric"
															label="الارتفاع"
															placeholder="الارتفاع"
															{...getFieldProps('_length')}
															{...getHelperProps('_length')}
															value={values._length ? values._length : ''}
														/>
													</Stack>

													{accountIs('alassalsweets', true) && (
														<CustomFields
															value={values.inputs}
															setFieldTouched={setFieldTouched}
															onChange={(v) => setFieldValue('inputs', v)}
															title={'حقول مخصصة'}
															inputKey="name"
															vertical
															maxInputs={1}
															allowChangeType
															fields={[
																{
																	label: 'اسم الحقل',
																	key: 'label',
																},
																{
																	label: 'اجباري',
																	type: 'checkbox',
																	key: 'required',
																},
															]}
															bgcolor={'#F3F3F3'}
															renderOptions={({options, handleInputChange}) => {
																return options.map((option) => {
																	return (
																		<Stack
																			spacing={1}
																			alignItems="center"
																			direction="row"
																			justifyContent="space-between"
																			key={option.value}
																		>
																			<Typography>{option.value}</Typography>
																			<TextField
																				type="text"
																				pattern="\d*"
																				sx={{width: 100}}
																				InputProps={{min: 0}}
																				label="السعر"
																				// {...getFieldProps('price')}
																				// {...getHelperProps('price')}
																				onChange={(e) =>
																					handleInputChange({...option, price: getNumber(e.target.value)})
																				}
																				value={option?.price ?? ''}
																			/>
																		</Stack>
																	);
																});
															}}
														/>
													)}

													{values.id && (
														<ProductBarcodeGenerator
															pid={product.id}
															sku={values?.sku}
															onChange={(v) => setFieldValue('sku', v)}
														/>
													)}

													{accountIs('crocotools', true) && (
														<Stack>
															<Typography>اضافات</Typography>
															<TextField
																label="vanex storage id"
																{...getFieldProps('custom_fields.vanex_storage_id')}
																{...getHelperProps('custom_fields.vanex_storage_id')}
																value={values.custom_fields?.vanex_storage_id ?? ''}
															/>
														</Stack>
													)}

													{accountIs('alassalsweets.com', true) && (
														<TextField
															label="الاسم بالانجليزي"
															{...getFieldProps('custom_fields.english_name')}
															{...getHelperProps('custom_fields.english_name')}
															value={values.custom_fields?.english_name ?? ''}
														/>
													)}

													{canAccess() && product?.creator && (
														<Typography>تم انشاء المنتج عن طريق: {product.creator?.name}</Typography>
													)}
												</Stack>
											</AccordionDetails>
										</Accordion>
									</Card>
								</Stack>
								{canSubmit && (
									<SubmitButton sx={{maxWidth: {md: 600}}} isSubmitting={isSubmitting}>
										تحديث المعلومات
									</SubmitButton>
								)}
								{canAccess(addProductPermission) && (
									<NavButton
										to="/products/create"
										variant="text"
										sx={{maxWidth: {md: 600}}}
										text="اضافة منتج اخر"
									/>
								)}
							</Stack>
						</Form>
					</FormikProvider>
					<AddPropertyValueModal
						isVisible={pvModalIsVisible}
						setVisibility={setPvModalVisibility}
						propertyId={propertyToAddTo}
						onCreate={onNewPropertyValueAdded}
					/>
				</Container>
			</AccessControlWrapper>
		</Page>
	);
}
