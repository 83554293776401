import {Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';

export default function Picker({
	size,
	options,
	name,
	label,
	helperText,
	error,
	valueKey,
	labelKey,
	onChange,
	value,
	defaultValue,
	fullWidth = false,
	disabled = false,
	displayEmpty = false,
	multiple = false,
	renderMenuItem,
	sx = {},
	renderValue,
}) {
	return (
		<FormControl size={size} sx={sx} fullWidth={fullWidth}>
			<InputLabel id="demo-simple-select-error-label">{label}</InputLabel>

			<Select
				renderValue={
					multiple
						? (selected) => {
								return (
									<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
										{selected.map((value, i) => {
											const option = options.find((o) => o == value || o[valueKey] == value);
											const _label = option[labelKey] ?? option;

											return <Chip key={i} label={_label} />;
										})}
									</Box>
								);
						  }
						: undefined
				}
				disabled={disabled}
				// displayEmpty={true}
				multiple={multiple}
				defaultValue={defaultValue}
				error={error}
				name={name}
				// labelId="demo-simple-select-error-label"
				// id="demo-simple-select-error"
				// native
				value={value}
				onChange={(e) =>
					onChange(
						e,
						options.find((o) => (o[valueKey] ?? o) == e.target.value),
					)
				}
				label={label}
				MenuProps={{
					PaperProps: {sx: {maxHeight: 300}},
				}}
				//   inputProps={{
				//     name: 'age',
				//     id: 'outlined-age-native-simple',
				//   }}
			>
				{/* <MenuItem aria-label="None" value="">
          {label}
        </MenuItem> */}
				{options.map((option) => (
					<MenuItem key={option[valueKey] ?? option} value={option[valueKey] ?? option}>
						{typeof renderMenuItem === 'function' ? renderMenuItem(option) : option[labelKey] ?? option}
					</MenuItem>
				))}
			</Select>
			{error && <FormHelperText error>{helperText}</FormHelperText>}
		</FormControl>
	);
}
