import {Button, Checkbox, FormControlLabel, Grid, Stack, Typography} from '@mui/material';
import {useQuery} from 'react-query';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import useBulkActions from 'src/hooks/useBulkActions';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {deleteOrder, getOrdersStatistics, updateOrder} from 'src/utils/AdminApi';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {orderStatus, orderStatusOptions} from 'src/utils/orderUtils';
import {canAccess} from 'src/utils/permissions';
import {getSiteCurrency} from 'src/utils/selectors';
import OrderStatusPicker from '../show/OrderStatusPicker';
import {OrderItem} from './Orders.item';
import OrderAssignPicker, {useSiteAdminsQuery} from '../show/OrderAssignPicker';
import Picker from 'src/components/inputs/Picker';
import ActionMenu from 'src/components/ui/ActionMenu';
import {stringify} from 'qs';
import {fromUTCdateToISO, getDateBoundaries} from 'src/utils/date';
import {DateTime} from 'luxon';
import useSite from 'src/hooks/useSite';

export function OrdersList({
	refetch,
	bulkActionEnabled = true,
	showStats = true,
	orders,
	...props
}) {
	const {
		selections,
		setSelections,
		bulkInputs,
		setBulkInputs,
		reset,
		getHandleCheck,
		getMainCheckboxProps,
		selectAll,
	} = useBulkActions({
		data: orders,
		initialFormValues: {},
	});

	const {filtersQueryString, filters} = useUrlFilters();

	const {data = {total_delivery_price: 0, total_revenue: 0, orders_count: 0}} = useQuery(
		['orders-statistics', filtersQueryString],
		() => {
			if (filters.fulfillment_date_gte) {
				filters.fulfillment_date_gte = new Date(filters.fulfillment_date_gte).toISOString();
			}
			if (filters.fulfillment_date_lte) {
				filters.fulfillment_date_lte = new Date(filters.fulfillment_date_lte).toISOString();
			}

			if (filters.selected_date_gte) {
				filters.selected_date_gte = fromUTCdateToISO(filters.selected_date_gte);
			}
			if (filters.selected_date_lte) {
				filters.selected_date_lte = DateTime.fromISO(fromUTCdateToISO(filters.selected_date_lte))
					.endOf('day')
					.toISO();
			}

			return getOrdersStatistics(stringify(filters));
		},
		{
			refetchOnWindowFocus: false,
			enabled: showStats === true,
		},
	);
	const {total_down_payment, total_delivery_price, total_revenue, orders_count} = data;

	return (
		<>
			{/* <Checkbox {...getMainCheckboxProps()} /> */}

			<Stack justifyContent="space-between" direction="row">
				{showStats && canAccess() && (
					<Stack alignItems="center" flexWrap="wrap" my={1} spacing={1} direction="row">
						<Typography>عدد الطلبات:</Typography>
						<Typography fontWeight="bold">{orders_count || 0}</Typography>
						<Typography>مجموع المبيعات:</Typography>
						<Typography fontWeight="bold">
							{total_revenue || 0} {getSiteCurrency()}
						</Typography>
						<Typography>مجموع قيمة التوصيل:</Typography>
						<Typography fontWeight="bold">
							{total_delivery_price || 0} {getSiteCurrency()}
						</Typography>
						<Typography>المجموع الكلي:</Typography>
						<Typography fontWeight="bold">
							{total_revenue + total_delivery_price} {getSiteCurrency()}
						</Typography>

						{/* {filters.payment_received === 'false' && (
									<>
										<Typography>العربون: </Typography>
										<Typography fontWeight="bold">
											{total_down_payment} {getSiteCurrency()}
										</Typography>
									</>
								)} */}
					</Stack>
				)}

				{bulkActionEnabled && canAccess() && <Checkbox sx {...getMainCheckboxProps()} />}
				{/* <ActionMenu items={[{text: 'تحديد الكل', action: selectAll}]} /> */}
			</Stack>

			<Grid container spacing={2} {...props}>
				{orders.map((order) => (
					<Grid key={order.id} item xs={12} sm={6} md={4}>
						<OrderItem
							order={order}
							handleCheck={getHandleCheck(order)}
							isChecked={Boolean(selections[order.id])}
						/>
					</Grid>
				))}
			</Grid>
			{!isNullOrEmpty(selections) && (
				<OrdersBulkActionForm
					refetch={refetch}
					selections={selections}
					bulkInputs={bulkInputs}
					reset={reset}
					setBulkInputs={setBulkInputs}
				/>
			)}
		</>
	);
}

function OrdersBulkActionForm({refetch, selections, bulkInputs, reset, setBulkInputs}) {
	const {data: allAdmins = []} = useSiteAdminsQuery();
	const tenant = useSite();

	const submit = async (e) => {
		e.preventDefault();
		if (bulkInputs.payment_received === 'no-change') delete bulkInputs.payment_received;
		if (bulkInputs.status === 'no-change') delete bulkInputs.status;

		const ordersToUpdate = Object.keys(selections).map((id) => {
			const order = selections[id];
			return updateOrder(order.uuid, bulkInputs);
		});
		await Promise.all(ordersToUpdate);
		// clear changes from selected items
		// handle the case if user was changing row then try to use bulk action with that row
		// clear selections
		reset();
		refetch();
	};

	const deleteSelected = async () => {
		const ordersToDelete = Object.values(selections).map(({uuid}) => deleteOrder(uuid));
		await Promise.all(ordersToDelete);
		reset();
		refetch();
	};
	return (
		<Stack
			sx={{
				zIndex: 10,
				position: 'fixed',
				bottom: 50,
				left: '50%',
				transform: 'translate(-50%, -50%)',
				background: 'white',
				p: 2,
				borderRadius: 3,
				boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
			}}
			spacing={1}
			flex={1}
		>
			<Stack flex={1} spacing={2} direction={{xs: 'column', md: 'row'}}>
				{/* <TextField
					sx={{minWidth: inputMinSize}}
					type="number"
					label="السعر"
					name="price"
					value={bulkInputs.price}
					onChange={(e) => setBulkInputs({...bulkInputs, price: e.target.value})}
					InputLabelProps={{shrink: true}}
					/> */}
				<OrderStatusPicker
					sx={{minWidth: 120}}
					label="الحالة"
					options={[
						{
							key: 'no-change',
							label: '-',
						},
						...orderStatusOptions,
					]}
					status={bulkInputs.status}
					onStatusChange={(e) => setBulkInputs({...bulkInputs, status: e.target.value})}
				/>

				<Picker
					sx={{minWidth: 140}}
					value={bulkInputs.payment_received}
					onChange={(e) => setBulkInputs({...bulkInputs, payment_received: e.target.value})}
					options={[
						{label: '-', value: 'no-change'},
						{label: 'تم الاستلام', value: true},
						{label: 'لم يتم الاستلام', value: false},
					]}
					labelKey="label"
					valueKey="value"
					label="حالة الدفع"
				/>

				{allAdmins.length > 0 && (
					<OrderAssignPicker
						sx={{minWidth: 200}}
						label="المسؤول"
						size="medium"
						noSelectionLabel="-"
						onChange={(assigneeIds) => setBulkInputs({...bulkInputs, assignee: assigneeIds?.[0]})}
						admins={allAdmins}
						value={bulkInputs.assignee ?? ''}
					/>
				)}

				<Stack spacing={1} direction="row" justifyContent="space-between">
					<Button onClick={submit}>حفظ</Button>
					{tenant.config?.features?.allow_order_delete && (
						<DeleteAlertDialog onDelete={deleteSelected} />
					)}
				</Stack>
			</Stack>
		</Stack>
	);
}
