import {Stack, Typography} from '@mui/material';
import {experimentalStyled as styled} from '@mui/material/styles';
import MuiLink from 'src/components/ui/StyledLink';
import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getProductLink} from 'src/utils/links';
import {getSiteCurrency} from 'src/utils/selectors';

export const ProductImgStyle = styled('img')({
	// top: 0,
	width: 50,
	height: 50,
	objectFit: 'cover',
	// position: 'absolute'
});

export function OrderProductItem({item}) {
	const {product, creator, price = 0, discount, quantity = 0} = item;
	const finalPrice = calcPriceAfterDiscount(price, discount);
	const hasDiscount = discount > 0;
	return (
		<MuiLink to={getProductLink(item.product)}>
			<Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
				<Stack spacing={4}>
					<Stack sx={{}} direction="row" spacing={4} justifyContent="space-between">
						<ProductImgStyle src={product.main_image?.url} />
						<Stack sx={{flexGrow: 1}}>
							<Typography variant="body1">{product?.name}</Typography>
							{!isNullOrEmpty(item.properties) && (
								<Stack direction="row" spacing={2}>
									{Object.keys(item.properties).map((prop) => (
										<Typography key={prop}>
											{prop}: {item.properties[prop]}
										</Typography>
									))}
								</Stack>
							)}
							{!isNullOrEmpty(item.inputs) && (
								<Typography>
									{item.inputs[0].label}: {item.inputs[0].value}
								</Typography>
							)}
							<Stack direction="row" spacing={4}>
								<Stack spacing={1} direction="row">
									<Typography>السعر:</Typography>
									<Typography style={{textDecoration: hasDiscount ? 'line-through' : 'none'}}>
										{price} {getSiteCurrency()}
									</Typography>
									{hasDiscount && (
										<Typography style={{color: 'red'}}>
											{finalPrice} {getSiteCurrency()}
										</Typography>
									)}
								</Stack>
								<Typography>الكمية: {quantity}</Typography>
							</Stack>

							{creator && (
								<Typography sx={{fontSize: 14, color: 'gray'}}>بواسطة: {creator.name}</Typography>
							)}
						</Stack>
						{/* end of product info container */}
					</Stack>

					{/* quanitiy and price  */}
				</Stack>
				{/* <Button variant="outlined">Delete</Button> */}

				{/* end of main stack */}
			</Stack>
		</MuiLink>
	);
}
