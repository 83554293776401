import {baseURL} from 'src/config';
import {getAdminToken} from './storage';

const axios = require('axios');

/**
 *
 * @param {*} url
 * @param {*} method
 * @param {*} body
 * @param {*} token
 * @param {*} files ex : {main_image:file1,gallery[0]:file2}
 * @returns {Promise<object>}
 */
export const request = async (url, method = 'GET', body, token, files) => {
	let headers;

	if (token) {
		headers = {
			// 'Cache-Control': 'private, max-age=100, must-revalidate',
			Authorization: `Bearer ${token}`,
		};
	} else {
		token = getAdminToken();
		if (token?.length > 0)
			headers = {
				Authorization: `Bearer ${token}`,
			};
	}

	let data;
	// makeing sure that files not null
	if (files) {
		// Create an object of formData
		data = new FormData();
		data.append('data', JSON.stringify(body));

		for (let key in files) {
			// handle the case of pass single file and array of files
			// ex : image -> single file , galleryImages array of files
			const file = files[key];
			if (!file) continue;

			if (Array.isArray(file)) {
				for (let f of file) {
					data.append(`files.${key}`, f);
				}
			} else {
				data.append(`files.${key}`, file);
			}
		}
		// data.append('files.main_image',files);
		// Update the formData object
	} else {
		data = body;
	}

	return new Promise((resolve, reject) => {
		axios
			.request({
				method,
				baseURL,
				url,
				data,
				headers,
			})
			.then((response) => resolve(response.data))
			.catch((err) => reject(err));
		// .catch((err) => reject(handleError(err)));
	});
};

export const uploadFileRequest = (file) => {
	const data = new FormData();
	data.append('files', file);
	const token = getAdminToken();
	const headers = {Authorization: `Bearer ${token}`};

	return new Promise((resolve, reject) => {
		axios
			.post(`${baseURL}/upload`, data, {
				headers,
				onUploadProgress: function (progressEvent) {
					var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					// console.log(percentCompleted);
				},
			})
			.then((response) => resolve(response.data))
			.catch((err) => reject(handleError(err)));
	});
};

const handleError = (err) => {
	if (err.response) {
		// Request made and server responded with error message ✅
		// return err.response.data // that how you get json response
		return err.response.data;
	} else if (err.request) {
		// The request was made but no response was received
		// if (err.code === 'ECONNREFUSED') {
		// }
		// return err.message;
		return 'تعذر الاتصال بالسيرفر';
	} else {
		// Something happened in setting up the request that triggered an Error
		return err.message;
	}
};

const endpoints = {
	orders: '/orders',
	stores: '/stores',
	reviews: '/reviews',
	categories: '/categories',
	products: '/products',
	ordersItems: '/order-items',
	login: '/auth/tenant/local',
	register: '/auth/tenant/local/register',
	forgetPassword: '/auth/forgot-password',
	resetPassword: '/auth/reset-password',
	customers: '/users',
	me: '/users/me',
	verifyPayment: '/tenant/subscription/verify',
	confirmPayment: '/tenant/subscription/confirm',
	addBanner: '/tenant/upload/banner',
	updateBanner: '/tenant/update/banner',
	deleteBanner: '/tenant/delete/banner',
};

const adminEndpoints = {
	login: '/auth/tenant/local',
	users: '/admin/users',
};

// don't forget headers
// @POST requests
export const createNewFeature = (feature) => request('/features', 'POST', feature);
export const toggleVoteForFeature = (featureID) =>
	request(`/features/${featureID}/toggleUserVote`, 'POST');
export const addBanner = (link, image) =>
	request(endpoints.addBanner, 'POST', {link}, null, {
		banner: image,
	});
export const updateBanner = (data, image = null) =>
	request(endpoints.updateBanner, 'POST', {...data}, null, image);
export const deleteBanner = (id) => request(endpoints.deleteBanner, 'POST', {public_id: id}, null);
export const addProduct = (product, mainImage, images) =>
	request(endpoints.products, 'POST', product, null, {
		main_image: mainImage,
		images,
	});
export const verifyPayment = (gateway, phoneNumber, amount, birthYear) =>
	request(endpoints.verifyPayment, 'POST', {
		gateway,
		body: {
			mobile_number: phoneNumber,
			amount: amount,
			birth_year: birthYear,
		},
	});
export const cardPayment = (duration, amount, cardNum, expDate, card_holder_name, bundle) =>
	request(endpoints.confirmPayment, 'POST', {
		gateway: 'bank',
		duration,
		subscription: 'basic',
		body: {
			card_number: cardNum,
			card_expiration: expDate,
			amount: amount,
			card_holder_name,
			return_url: window.location.origin + '/processing',
		},
		bundle,
	});

export const localCardPayment = (gateway, duration, amount, bundle) =>
	request(endpoints.confirmPayment, 'POST', {
		gateway,
		duration,
		body: {
			amount: amount,
			return_url: window.location.origin + '/processing',
		},
		bundle,
	});

export const cardPaymentCallback = (data) => request('/tenant/card/confirm/callback', 'POST', data);
export const confirmPayment = (gateway, duration, code, amount, processId, bundle) =>
	request(endpoints.confirmPayment, 'POST', {
		gateway,
		bundle,
		duration,
		subscription: 'basic',
		body: {
			code,
			amount,
			process_id: processId,
		},
	});

export const createStore = (token, name, owner, description, link, logo) =>
	request(
		endpoints.stores,
		'POST',
		{name, owner, employees: [owner], description, link},
		token,
		logo ? {logo} : null,
	);

export const addCategory = (name, parent, row_order, image) =>
	request(endpoints.categories, 'POST', {name, row_order, parent}, null, image ? {image} : null);
// @GET requests

export const getNotifications = () => request('/notifications');
export const getAllFeatures = () => request('/features');
export const getStoreProgress = () => request('/tenant/progress');
export const getCustomers = (index) => request(`/tenant/customers?_start=${index}&_limit=30`);

export const getUserMessages = () => request('/messages/latest');
export const getConversitionMessages = (storeId, userId) =>
	request(`/messages/${storeId}/${userId}`);

export const getCurrencies = () => request('/currencies');
export const getCategories = () => request('/tenant/categories?_sort=row_order:desc');
// export const getCategoryStats = (id) => request(`/categories/${id}/stats`);
export const getCategoriesStats = (categories, from, to) =>
	request(`/categories-stats`, 'post', {categories, from, to});
export const getCategoryStock = (id) => request(`/categories/${id}/stock`);
export const getOrders = (q = '') => request('/tenant/orders' + q);
export const getActiveorders = () => request('/orders/admin/active-orders');
export const getProduct = (id) => request(`tenant/products/${id}?_publicationState=preview`);
export const getProductInventoryChanges = (productId, variationId) =>
	request(`tenant/products/${productId}/inventory-changes`);
export const getProductPurchaseItems = (productId, variationId) =>
	request(`tenant/products/${productId}/purchase-items`);

export const getProperty = (id) => request(`/properties/${id}`);
export const getOrderReceipt = (uuid) => request(endpoints.orders + `/${uuid}/receipt`);
export const getOrder = (id) => request(endpoints.orders + `/${id}`);
export const getStores = (q = '') => request(endpoints.stores + q);
export const getUserInfo = (token) => request('/users/me', 'GET', null, token);
export const getStore = (id) => request(endpoints.stores + `/${id}`);
export const getProductReviews = () => request(endpoints.reviews);
// filter by product name, category

export const getProducts = (q) => request('/tenant/products' + q);
export const getOutOfStockProducts = () => request('/products/out-of-stock');
export const getGeneralStatistics = (fromDate, toDate) =>
	request(`/tenant/generalStatistics?fromDate=${fromDate}&toDate=${toDate}`);
export const getStatisticsInDatePeriod = (startDate = '', endDate = '') => {
	const q = !endDate ? `?startDate=${startDate}` : `?startDate=${startDate}&endDate=${endDate}`;
	return request(`/orders/range${q}`);
};
// @PUT

export const updateOrder = (id, order) => request(endpoints.orders + `/${id}`, 'PUT', order);
export const confirmReceivePayment = (id) =>
	request(endpoints.orders + `/${id}/confirm-payment`, 'POST');
export const returnOrder = (id, return_down_payment) =>
	request(endpoints.orders + `/${id}/return`, 'POST', {return_down_payment});
export const exchangeOrderItems = (id, returned_items, added_items) =>
	request(endpoints.orders + `/${id}/exchange-items`, 'POST', {
		returned_items,
		added_items,
	});

export const manualUpdateProductQuantity = ({productId, variationId, description, newQuantity}) =>
	request(`/update-quantity`, 'POST', {
		productId,
		variationId,
		description,
		newQuantity,
	});

export const getQuantityUpdate = (id) => request(`/product-quantity-updates/${id}`);
export const deleteQuantityUpdate = (id) => request(`/product-quantity-updates/${id}`, 'DELETE');

export const updateStore = (id, store) => request(endpoints.stores + `/${id}`, 'PUT', store);
export const updateProperties = (id, changes) => request(`/properties/${id}`, 'PUT', changes);
export const updatePropertyValues = (id, changes) =>
	request(`/property-values/${id}`, 'PUT', changes);
export const updateProduct = (id, product, image, images) =>
	request(endpoints.products + `/${id}`, 'PUT', product, null, {
		images,
		main_image: image,
	});
export const updateProductDiscount = (id, sale_percentage, price) =>
	request(endpoints.products + `/${id}`, 'PUT', {
		sale_percentage,
		price,
	});
export const updateProduct2 = (id, product) =>
	request(endpoints.products + `/${id}`, 'PUT', product);
export const updateCategory = (id, name, parent, image) =>
	request(endpoints.categories + `/${id}`, 'PUT', {name, parent}, null, image ? {image} : undefined);

export const reorderCategory = (id, newPosition) =>
	request(endpoints.categories + `/${id}/reorder?newPosition=${newPosition}`);

// @Delete
export const deleteProperties = (id) => request(`/properties/${id}`, 'DELETE');

export const deletePropertyValue = (id) => request(`/property-values/${id}`, 'DELETE');

export const deleteCategory = (id) => request(endpoints.categories + `/${id}`, 'DELETE');

export const deleteProduct = (id) => request(endpoints.products + `/${id}`, 'DELETE');

export const deleteOrder = (orderUUID) => request('/orders/' + orderUUID, 'DELETE');

// CRUD Admins

// #### admin routes
export const createOrder = ({
	address,
	email,
	phone,
	name,
	cart,
	delivery_price,
	note,
	status,
	delivery_info,
	discount = 0,
	down_payment = 0,
	payment_received,
	is_cashier = false,
	custom_fields,
	payment,
	selected_date,
}) =>
	request('/tenant/createOrder', 'POST', {
		address,
		email,
		phone,
		name,
		cart,
		delivery_price,
		note,
		status,
		delivery_info,
		custom_fields,
		discount,
		down_payment,
		payment_received,
		payment,
		is_cashier,
		selected_date,
	});

export const updateTenant = (id, site_name, style, config, logo, icon) =>
	request('/tenants/' + id, 'PUT', {site_name, logo, style, config});

export const updateTenantConfig = (id, config) => request('/tenants/' + id, 'PUT', {config});
export const updateTenantStoreConfig = (id, store_config) =>
	request('/tenants/' + id, 'PUT', {store_config});

export const setUpShippingAccount = (config) => request('/setUpShippingAccount', 'POST', config);

export const testProviderCredentials = (provider) =>
	request('/testProviderCredentials', 'POST', {provider});

export const addProductVariations = (variations) =>
	request('/variations/createMultiple', 'POST', {variations});

export const updateVariation2 = (variationId, changes) =>
	request('/variations/' + variationId, 'PUT', changes);

export const updateVariation = (
	variationId,
	price,
	quantity,
	isDeleted,
	discount,
	addedQuantity,
	cost_per_unit,
) =>
	request('/variations/' + variationId, 'PUT', {
		price,
		// quantity,
		discount,
		isDeleted,
		addedQuantity,
		cost_per_unit,
	});

export const deleteVariation = (variationId) => request('/variations/' + variationId, 'DELETE');

export const addPropertyValue = (propertyId, propertyValueName) =>
	request('/property-values', 'POST', {
		value: propertyValueName,
		property: propertyId,
	});
export const addProperty = (name) =>
	request('/properties', 'POST', {
		name,
	});
export const createPropertyWithValues = (name, values) =>
	request('/propertiesWithValues', 'POST', {
		name,
		values,
	});
export const setAllNotificationSeen = () => request('/notifications/seeAll', 'POST');
export const getProperties = () => request('/tenant/properties');
export const createTenant = (host, site_name, Owner, type, style, config) =>
	request('/tenants', 'POST', {host, site_name, Owner, type, style, config});
export const adminRegister = (name, phone, email, password) =>
	request(endpoints.register, 'POST', {name, phone, email, password});
export const adminLogin = (email, password) =>
	request(adminEndpoints.login, 'POST', {identifier: email, password});
export const getAdminUserInfo = (token) => request(endpoints.me, 'GET', null, token);

export const updateAdminUserInfo = (user) => request('/user/update', 'PUT', user);

const admins_endpoint = '/admins';
// delilver methods routes
export const deleteDeliveryMethod = (id) => request(`/delivery-infos/${id}`, 'DELETE');
export const getDeliveryMethodById = (id) => request(`/delivery-infos/${id}`, 'GET');
export const updateDeliveryMethods = (id, name, details) =>
	request(`/delivery-infos/${id}`, 'PUT', {name, details});
export const updateDeliveryMethods2 = (id, updates) =>
	request(`/delivery-infos/${id}`, 'PUT', updates);
export const getDeliveryMethods = () => request('/tenant/delivery_info', 'GET');
export const getCities = () => request('/cities?parent_null=true', 'GET');
export const createDeliveryMethod = (name, type) =>
	request('/delivery-infos', 'POST', {name, type});
// ==============================================================================================

export const changePassword = (current_password, new_password) =>
	request('/user/change-password', 'PUT', {current_password, new_password});

export const setFcmToken = (deviceId, fcmToken) =>
	request('/users/fcmToken', 'POST', {deviceId, fcmToken});

export const cashPaymentRequest = (phone, address, bundle) =>
	request('/cash-subscription-payment', 'POST', {
		phone,
		address,
		bundle,
	});

export const printReceipt = (orderId, orderUUID) => {
	return new Promise((resolve, reject) => {
		fetch(`${baseURL}/orders/${orderUUID}/receipt`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})
			.then((res) => res.arrayBuffer())
			.then((res) => {
				resolve(res);
				// download(res, `فاتورة-${orderId}.pdf`);
			})
			.catch(reject);
	});
};

// crud purchases
export const getInvoices = (q) => request('/invoices' + q);
export const getInvoicesTotal = (q) => request('/invoices/total?' + q);
export const getPurchases = (q = '?_limit=-1') => request('/purchases' + q);
export const getPurchase = (id) => request('/purchases/' + id);
// export const getPurchases = () => request('/purchases');
export const deletePurchases = (id) => request('/purchases/' + id, 'DELETE');
export const deletePurchasesItem = (id) => request('/purchases/items/' + id, 'DELETE');
export const updatePurchases = (id, updates) => request('/purchases/' + id, 'PUT', updates);
export const createPurchases = (inputs) => request('/purchases/', 'POST', inputs);

// tenant admins management routes
export const getAdminPermissions = () => request(admins_endpoint + '/permissions', 'GET');
export const getAdmins = () => request(admins_endpoint, 'GET');
export const getOrdersAsString = (q = '') => request('/orders/string?' + q);
export const deleteAdmin = (id) => request(admins_endpoint + `/${id}`, 'DELETE');
export const addAdmin = (newAdmin) => request(admins_endpoint, 'POST', newAdmin);
export const updateAdmin = (id, updatedAdmin) =>
	request(admins_endpoint + `/${id}`, 'PUT', updatedAdmin);

// CRUD order items
export const deleteOrderItems = (orderItemId, reason) =>
	request(endpoints.ordersItems + `/${orderItemId}`, 'DELETE', {
		reason,
	});
export const updateOrderItem = (orderItemId, updates) =>
	request(endpoints.ordersItems + `/${orderItemId}`, 'PUT', updates);

export const addOrderItem = (orderItem) => request(endpoints.ordersItems, 'POST', orderItem);
export const getOrdersStatistics = (query) => request('/orders-statistics?' + query, 'GET');
export const getShippingProviders = () => request('/shipping/providers');
