/* eslint-disable no-unreachable */
import DeleteIcon from '@mui/icons-material/Delete';
import {Button, Divider, IconButton, Stack, TextField, Typography} from '@mui/material';
import useVisible from 'src/hooks/useVisible';
import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getImageSrc} from 'src/utils/productUtils';
import {getSiteCurrency} from 'src/utils/selectors';
import ProductsGridSelection from '../products/common/ProductsGridSelection/ProductsGridSelection';
import ResponsiveDialog from 'src/components/modals/ResponsiveDiaog';
import CartItem from './CartItem.item';

export default function CartItemSelector({
	title,
	handleItemQuantityChange,
	checkAddedItemExists,
	handleAddItem,
	handleRemoveItem,
	handleItemChange,
	addedProducts,
	resetSelections,
}) {
	const {isVisible, show, hide, toggle, setIsVisible} = useVisible();

	return (
		<>
			{/* Selection title with button to select */}
			<Stack spacing={1} direction="row" justifyContent="space-between">
				<Typography variant="subtitle2">{title}</Typography>
				<Button onClick={show}>تحديد</Button>
			</Stack>

			{/* UI to let user select items when no items selected */}
			{isNullOrEmpty(addedProducts) && (
				<Stack justifyContent="center" alignItems="center">
					<img src="/static/illustrations/items-selection.png" height={100} />
					<Button onClick={show} variant="text">
						{title}
					</Button>
				</Stack>
			)}

			{/* Cart Item list */}
			<Stack my={1} spacing={1}>
				<Stack mt={2} spacing={2}>
					{addedProducts.map((item) => {
						return (
							<CartItem
								handleItemChange={handleItemChange}
								handleItemQuantityChange={handleItemQuantityChange}
								handleRemoveItem={handleRemoveItem}
								item={item}
								key={item.id}
							/>
						);
					})}
				</Stack>
			</Stack>

			{/* Item selection modal */}
			<ResponsiveDialog
				maxWidth="md"
				open={isVisible}
				setOpen={setIsVisible}
				actions={
					<Button sx={{py: 1.1}} fullWidth onClick={() => hide()} variant="contained">
						تأكيد
					</Button>
				}
			>
				<ProductsGridSelection
					columns={{xl: 3}}
					handleItemQuantityChange={handleItemQuantityChange}
					checkAddedItemExists={checkAddedItemExists}
					handleAddItem={handleAddItem}
					handleRemoveItem={handleRemoveItem}
					addedProducts={addedProducts}
					resetSelections={resetSelections}
				/>
			</ResponsiveDialog>
		</>
	);
}
